import React from "react"
import { HeadlineAndParagraph } from "./index"
import { HorizontalRuler, Headline, Paragraph } from "src/components/primitives"

export const CaseStudyCapabilities = ({ children }) => {
  return (
    <>
      <HorizontalRuler />
      <HeadlineAndParagraph>
        <Headline>Expertise</Headline>
        <Paragraph>{children}</Paragraph>
      </HeadlineAndParagraph>
    </>
  )
}
