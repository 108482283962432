import * as React from "react"
import { styled } from "src/stitches"

import { Block, Grid } from "src/components/primitives"

const LogosBlock = styled(Block, {})

export const LogosGrid = ({ children }) => {
  return (
    <LogosBlock>
      <Grid gap columns={{ bp0: "$2", bp3: "$4" }}>
        {children}
      </Grid>
    </LogosBlock>
  )
}
