import React, {
  Children,
  useCallback,
  useState,
  useEffect,
  useRef,
} from "react"

import { styled } from "src/stitches"

import { Expandable, Grid, Col } from "src/components/primitives"

export const Divider = styled("div", {
  display: "block",
  maxWidth: "100%",
  borderBottom: "none",
  borderTop: "1px solid $mono70",
  margin: 0,
  marginTop: "30px",
  marginBottom: "31px",
  bp1: {
    marginTop: "30px",
    marginBottom: "36px",
  },
  bp3: {
    marginTop: "30px",
    marginBottom: "29px",
  },
  bp4: {
    marginTop: "48px",
    marginBottom: "52px",
  },
})

const AccordionContentBlock = styled("div", {
  paddingTop: "14px",
  paddingBottom: "10px",
  color: "$mono50",
  bp3: {
    paddingTop: "19px",
    paddingBottom: "23px",
  },
  bp4: {
    paddingTop: "22px",
    paddingBottom: "14px",
  },
})

const AccordionButton = styled("button", {
  margin: "0px",
  width: "100%",
  paddingBottom: "11px",
  color: "$mono0",

  "&:hover": {
    color: "$mono50",
  },
  "&:focus-visible": {
    color: "$mono50",
  },

  bp3: {
    paddingBottom: "6px",
  },
  bp4: {
    paddingBottom: "13px",
  },
})

const AccordionTitle = styled("div", {
  display: "block",
  margin: "0px",
  fontWeight: "$300",
  fontSize: "45px",
  lineHeight: 1.06,
  paddingTop: "5px",
  bp1: {
    paddingTop: 0,
  },
  bp3: {
    fontSize: "86px",
    lineHeight: 1.04,
  },
  bp4: {
    fontSize: "90px",
    lineHeight: 1,
  },
})

const AccordionNumeral = styled("div", {
  display: "block",
  whiteSpace: "pre-line",
  margin: "0px",
  color: "$mono50",
  fontWeight: "$300",
  fontSize: "18px",
  lineHeight: 1.66,
  bp3: {
    fontSize: "20px",
    lineHeight: 1.5,
    paddingTop: "9px",
  },
  bp4: {
    fontSize: "22x",
    lineHeight: 1.63,
    paddingTop: "6px",
  },
})

export const Accordion = ({ id, numeral, title, children }) => {
  const [expanded, setExpanded] = useState(false)
  const elRef = useRef<HTMLDivElement>(null)

  const handleButtonClick = useCallback(() => {
    setExpanded(state => !state)
  }, [setExpanded])

  useEffect(() => {
    if (expanded && elRef.current) {
      elRef.current.focus()
    }
  }, [expanded])

  return (
    <>
      <Divider />
      <AccordionButton
        tabIndex={0}
        onClick={handleButtonClick}
        aria-expanded={expanded}
        id={`${id}-tab`}
        aria-controls={`${id}-panel`}
        style={{ width: "100%" }}
        className="accordion"
      >
        <Grid
          gap={{ bp1: true }}
          columns={{ bp0: "$1", bp1: "$8", bp3: "$12" }}
        >
          <Col>
            <AccordionNumeral aria-hidden={true}>{numeral}</AccordionNumeral>
          </Col>
          <Col
            start={{ bp0: "$1", bp1: "$2", bp3: "$2" }}
            end={{ bp0: "$2", bp1: "$9", bp3: "$13" }}
          >
            <AccordionTitle>{title}</AccordionTitle>
          </Col>
        </Grid>
      </AccordionButton>
      <Grid
        gap={{ bp1: true }}
        columns={{ bp0: "$1", bp1: "$8", bp3: "$12" }}
        id={`${id}-panel`}
        ref={elRef}
        role="region"
        aria-label={`${title} Panel`}
        tabIndex={-1}
        css={{
          "&:focus": {
            outline: "none",
          },
        }}
      >
        <Col
          start={{ bp0: "$1", bp1: "$2", bp3: "$2" }}
          end={{ bp0: "$2", bp1: "$8", bp3: "$8" }}
        >
          <Expandable expanded={expanded}>
            {Children.map(children, child => {
              return <AccordionContentBlock>{child}</AccordionContentBlock>
            })}
          </Expandable>
        </Col>
      </Grid>
    </>
  )
}
