import { styled } from "src/stitches"

export const HeadlineAndParagraph = styled("div", {
  paddingTop: "19px",
  paddingBottom: "22px",
  "& *": {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
    margin: 0,
  },
  "& p": {
    marginTop: "31px",
  },
  bp3: {
    paddingTop: "16px",
    paddingBottom: "29px",
    "& *": {
      paddingTop: "0 !important",
      paddingBottom: "0 !important",
      margin: 0,
    },
    "& p": {
      marginTop: "33px",
    },
  },
  bp4: {
    paddingTop: "33px",
    paddingBottom: "44px",
    "& *": {
      paddingTop: "0 !important",
      paddingBottom: "0 !important",
      margin: 0,
    },
    "& p": {
      marginTop: "43px",
    },
  },
})
