import { styled } from "src/stitches"

export const HeaderSecondary = styled("header", {
  paddingTop: "112px",
  paddingBottom: "8px",
  bp3: {
    paddingTop: "120px",
    paddingBottom: "16px",
  },
  bp4: {
    paddingTop: "128px",
    paddingBottom: "24px",
  },
})
