import { styled } from "src/stitches"

export const HeaderHero = styled("header", {
  paddingTop: "200px",
  paddingBottom: "128px",
  bp3: {
    paddingTop: "184px",
    paddingBottom: "56px",
  },
  bp4: {
    paddingTop: "192px",
    paddingBottom: "64px",
  },
})
