import { styled } from "src/stitches"

export const HeaderSuperhero = styled("header", {
  paddingTop: "136px",
  bp3: {
    paddingTop: "156px",
  },
  bp4: {
    paddingTop: "164px",
  },
})
