import React, { useCallback, useState } from "react"
import { Link } from "gatsby"

import { styled } from "src/stitches"

import {
  HorizontalRuler,
  Block,
  HeadlineSecondary,
} from "src/components/primitives"

const StyledCaseStudyContainer = styled("li", {
  display: "block",
  width: "100%",
  contentVisibility: "auto",
  containIntrinsicSize: "550px",
  bp3: { containIntrinsicSize: "800px" },
  bp4: { containIntrinsicSize: "1000px" },
})

const StyledCaseStudyContents = styled("div", {
  display: "block",
  position: "relative",
  width: "100%",
})

const StyledCaseStudyLink = styled(Link, {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 10,
  "&:focus": {
    outline: "none",
  },
})

export const HomeFeaturedCaseStudy = ({
  title,
  subtitle,
  link,
  children,
  ...rest
}) => {
  const [on, setOn] = useState(false)

  const onOver = useCallback(() => {
    setOn(() => true)
  }, [setOn])

  const onOut = useCallback(() => {
    setOn(() => false)
  }, [setOn])

  return (
    <StyledCaseStudyContainer {...rest}>
      <StyledCaseStudyContents>
        <HeadlineSecondary>
          <span>{title}</span>
          {subtitle}
        </HeadlineSecondary>
        <Block>{children}</Block>
        <StyledCaseStudyLink
          to={link}
          aria-label={`Go to ${title} case study`}
          onMouseOver={onOver}
          onFocus={onOver}
          onBlur={onOut}
          onMouseLeave={onOut}
        />
      </StyledCaseStudyContents>
      <HorizontalRuler aria-hidden={true} />
    </StyledCaseStudyContainer>
  )
}
